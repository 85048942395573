import React from "react"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"

import htmlSerializer from "../utils/htmlSerializer"

import Reveal from "react-reveal/Reveal"

import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import Button from "../components/shared/button"

// import FeatureText from "../components/widgets/featureText";
import Banner from "../components/widgets/banner"
import ThumbnailList from "../components/widgets/thumbnailList"
import Emoji from "a11y-react-emoji"
import CartoonGroup from "../components/widgets/cartoonGroup"

const IndexPage = ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  if (!doc) return null

  const scrollToIntro = () => {
    var targetElement = document.getElementById("intro")
    var elementY =
      window.pageYOffset + targetElement.getBoundingClientRect().top
    var startingY = window.pageYOffset
    var diff = elementY - startingY
    var start
    var duration = 500

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      // Elapsed milliseconds since start of scrolling.
      var time = timestamp - start
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1)

      window.scrollTo(0, startingY + diff * percent)

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })

    targetElement.setAttribute("tabindex", -1)
    targetElement.focus()
  }

  return (
    <Layout pageTitle={``}>
      <SEO
        title={doc.node.meta_title ? doc.node.meta_title : "Home"}
        image={doc.node.meta_image}
        description={doc.node.meta_description}
      />

      <Banner content={doc.node.banner_title} type="full" headerSize={`large`}>
        <Button
          text={doc.node.banner_button_text}
          link={doc.node.banner_button}
          emoji={doc.node.banner_button_emoji}
          emojiLabel={doc.node.banner_button_emoji_label}
        />

        <button
          onClick={() => scrollToIntro()}
          className={`c-button c-button--icon`}
        >
          <Emoji
            symbol={`👇`}
            label={`Finger pointing down`}
            className={`c-button__symbol`}
          />
          <span className={`show-for-sr`}>Scroll</span>
        </button>
      </Banner>

      <Reveal cascade effect="fadeInUp">
        <section id="intro" className={`grid-container u-collapse-top`}>
          <div className={`grid-x`}>
            <div className={`cell small-12 medium-10`}>
              <RichText
                render={doc.node.intro_text}
                htmlSerializer={htmlSerializer}
              />

              <Button
                text={doc.node.intro_button_text}
                link={doc.node.intro_button}
                emoji={doc.node.intro_button_emoji}
                emojiLabel={doc.node.intro_button_emoji_label}
              />
            </div>
          </div>
        </section>
      </Reveal>

      <ThumbnailList doc={doc} />

      <Reveal cascade effect="fadeInUp">
        <section className={`grid-container u-margin-top-lg`}>
          <div className={`grid-x`}>
            <div className={`cell small-12`}>
              <RichText
                render={doc.node.about_text}
                htmlSerializer={htmlSerializer(`size`)}
              />

              <Button
                text={doc.node.about_button_link_text}
                link={doc.node.about_button}
                emoji={doc.node.about_button_emoji}
                emojiLabel={doc.node.about_button_emoji_label}
              />
            </div>
          </div>
        </section>
      </Reveal>

      <CartoonGroup />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    prismic {
      allHomepages {
        edges {
          node {
            meta_title
            meta_description
            meta_image
            meta_imageSharp {
              publicURL
            }
            page_title
            banner_title
            banner_button {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            banner_button_text
            banner_button_emoji
            banner_button_emoji_label
            intro_text
            intro_button {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            intro_button_text
            intro_button_emoji
            intro_button_emoji_label
            case_study_group_title
            case_studies {
              case_study {
                ...caseStudies
              }
            }
            case_study_button {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            case_study_button_text
            case_study_button_emoji
            case_study_button_emoji_label
            about_button {
              _linkType
              ... on PRISMIC_Homepage {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Support {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_list {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Blog_post {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Approach {
                _meta {
                  type
                }
              }
              ... on PRISMIC_About {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Our_work {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Person {
                _meta {
                  type
                }
              }
              ... on PRISMIC_Basic_page {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC_Case_study {
                _linkType
                _meta {
                  type
                  uid
                }
              }
              ... on PRISMIC__ExternalLink {
                _linkType
                url
              }
            }
            about_text
            about_button_link_text
            about_button_emoji
            about_button_emoji_label
          }
        }
      }
    }
  }
`

export default IndexPage
