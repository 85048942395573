import React, { useRef, useState } from "react"

import Image from "../shared/image"
import PageLink from "../shared/pageLink"
import { RichText } from "prismic-reactjs"
import Button from "../shared/button"
import useMousePosition from "../hooks/useMousePosition"

import Reveal from "react-reveal/Reveal"

const Item = ({ caseStudy }) => {
  const listItem = useRef(null)
  const listItemCursor = useRef(null)
  const cursorSize = 133
  const { mousePosition } = useMousePosition()

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  const handleMouseMove = e => {
    const boxSize = listItem.current.getBoundingClientRect()

    setX(mousePosition.x - boxSize.left - cursorSize / 2)
    setY(mousePosition.y - boxSize.top - cursorSize / 2)
  }

  return (
    // override: jsx-a11y interactive element with key/mouse events - non-issue
    // eslint-disable-next-line
    <li
      ref={listItem}
      onMouseMove={e => handleMouseMove(e)}
      style={{
        "--theme-color": caseStudy.case_study.theme_color1,
        "--a11y-theme-color": caseStudy.case_study.a11y_theme_color,
      }}
      ie-style={`--theme-color: ${caseStudy.case_study.theme_color1}; --a11y-theme-color: ${caseStudy.case_study.a11y_theme_color}`}
      className={`c-thumbnail-list__item`}
    >
      <PageLink
        item={caseStudy.case_study}
        transition={`thumbnail`}
        className={`c-thumbnail-list__link`}
      >
        <div className={`c-thumbnail-list__content`}>
          <div
            ref={listItemCursor}
            className={`c-thumbnail-list__cursor`}
            style={{ transform: `translate3d(${x}px, ${y}px, 0)` }}
          ></div>
          <h3>{caseStudy.case_study.key_question}</h3>
        </div>

        <div className={`c-thumbnail-list__action`}>
          <div className={`c-thumbnail-list__image`}>
            <span className={`c-thumbnail-list__image__container`}>
              <Image
                sharpType="fluid"
                sharpImage={
                  caseStudy.case_study.feature_imageSharp.childImageSharp.fluid
                }
                alt={caseStudy.case_study.feature_image.alt}
                defaultImage={caseStudy.case_study.feature_image.url}
              />
            </span>
          </div>
        </div>
      </PageLink>
    </li>
  )
}

const ThumbnailList = ({ doc }) => {
  return (
    <Reveal effect="fadeInUp" fraction={0.05}>
      <section className={`grid-container u-margin-top-lg`}>
        <div className={`grid-x`}>
          <div className={`cell large-7`}>
            <RichText render={doc.node.case_study_group_title} />
          </div>
          <div className={`cell`}>
            <ul className={`c-thumbnail-list`}>
              {doc.node.case_studies.map(caseStudy => (
                <Item
                  key={caseStudy.case_study._meta.id}
                  caseStudy={caseStudy}
                />
              ))}
            </ul>

            <Button
              text={doc.node.case_study_button_text}
              link={doc.node.case_study_button}
              emoji={doc.node.case_study_button_emoji}
              emojiLabel={doc.node.case_study_button_emoji_label}
            />
          </div>
        </div>
      </section>
    </Reveal>
  )
}

export default ThumbnailList
