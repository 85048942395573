import React from "react"
import { StaticQuery, graphql } from "gatsby";
import SVG from 'react-inlinesvg';

import Reveal from 'react-reveal/Reveal';

const CartoonGroup = () => {

  return (
    <StaticQuery
      query={`${query}`}
      render={(data) => {
        const team = data.prismic.allTeams.edges.slice(0,1).pop();
        if (!team) return null;

        return (
          <Reveal cascade effect="fadeInUp">
            <section className={`c-cartoon-group`}>
              
              {team.node.cartoons.map((person, index) =>
                <div key={index} className={`c-cartoon-group__item`} >
                  <SVG src={person.person.url} uniquifyIDs={true} />
                </div>
              )}
              
            </section>
          </Reveal>
        )
      }}
    />
  )
};

export default CartoonGroup

export const query = graphql`
  query CartoonGroupQuery {
    prismic {
      allTeams {
        edges {
          node {
            cartoons {
              person
            }
          }
        }
      }
    }
  }`
